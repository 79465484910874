export function RemoveAccents(myString) {
  let newString = myString
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .replace(/\s+/g, "")
    .replace(/[^a-zA-Z0-9]/g, "")
    .toLowerCase();
  //console.log(myString, " | " + newString);
  return newString;
}
