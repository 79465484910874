import React from "react";

//#region MY STYLES
const myStyles = {
  myText2: {
    fontSize: 16,
    color: "black",
    textAlign: "center",
    lineHeight: "25px",
  },
};
function WarningFormat({ warns }) {
  const warn = warns.map((element, index) => {
    //console.log(element);
    return (
      <span style={myStyles.myText2} key={index}>
        {element}
        <br></br>
      </span>
    );
  });

  return (
    <div>
      <h2
        style={{
          margin: "0px",
          marginTop: "40px",
          textAlign: "center",
          whiteSpace: "pre-wrap",
        }}
      >
        Este proyecto no está disponible para Plano Tipo.
      </h2>
      <ul
        style={{
          margin: "0px",
          marginTop: "15px",
          padding: "0",
          whiteSpace: "pre-wrap",
        }}
      >
        {warn}
      </ul>
    </div>
  );
}

export default WarningFormat;
