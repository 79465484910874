import { useState, createContext } from "react";
import "./App.css";
import PlanoTipo from "./PlanoTipo";
//import LoginGoogleSSO from "./LoginGoogleSSO";
import LoginWorkobot from "./LoginWorkobot";

export const AppContext = createContext();

function App() {
  const client = "CBB";
  const [userData, setUserData] = useState(undefined);

  return (
    <AppContext.Provider
      value={{
        client,
        userData,
        setUserData,
      }}
    >
      <div className="App">
        <div className="background">
          <img src="/imgs/fondo-proyectos.svg" alt="" />
        </div>
        <div className="navBar">
          <div className="clientLogo flex-center">
            <img src="imgs/logo-bolivar_scroll.svg" alt="navBar-Logo" />
          </div>
          <span className="navBar-Greeting">
            {userData !== undefined
              ? `Hola ${userData.name},\nBienvenido a Plano Tipo.`
              : "Bienvenido a Plano Tipo."}
          </span>
        </div>
        {userData && <PlanoTipo userData={userData} />}
        {!userData && (
          <div id="homeContainer" className="flex-center">
            <img
              className="homeContainer-Logo"
              src="/imgs/LogoCliente.png"
              alt="Logo Constructora Bolívar"
            />
            <div className="divider" />
            <div className="homeContainer-description">
              <p style={{ fontSize: "16px", padding: 0 }}>Aplicación Web</p>
              <h1>Plano Tipo</h1>
              <p style={{ fontSize: "16px", padding: 0 }}>
                Herramienta para crear documentos <b>PDF</b> con los planos
                ambientados de una unidad.
              </p>
            </div>
          </div>
        )}
        {/* <LoginGoogleSSO /> */}
        <LoginWorkobot />
        <div className="footer flex-center">
          <img
            className="footerIcon"
            src="imgs/FavIcon__Workobot_02.png"
            alt="Logo Workobot.com"
            style={{ height: "25px" }}
          />
          <span
            style={{
              textAlign: "right",
              fontSize: "12px",
              lineHeight: "12px",
              color: "white",
            }}
          >
            Desarrollado por{" "}
            <a
              href="https://www.workobot.com/"
              target={"_blank"}
              rel="noreferrer"
              style={{ color: "white" }}
            >
              Workobot.com
            </a>
          </span>
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default App;
