import { useState, useContext } from "react";
import axios from "axios";
import "./LoginWorkobot.css";
import { AppContext } from "./App";

function LoginWorkobot() {
  const { setUserData, userData } = useContext(AppContext); //client,

  const loginDomains = ["@constructorabolivar.com", "@workobot.com"];
  const [domain, setDomain] = useState(loginDomains[0]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [loggedIn, setLoggedIn] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleUserInputChange = (e) => {
    //const updatedEmail = e.target.value.replace(/@/g, "");
    //setEmail(updatedEmail);
    setEmail(e.target.value);
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!email.trim() || !password.trim()) {
      setErrorMessage("Por favor, completa todos los campos.");
      return;
    }

    //console.log(domain);
    const fullEmail = email + domain;

    setIsLoading(true);
    try {
      const result = await LoginWK(fullEmail, password);
      //console.log(result);
      if (result.Message === "SUCCESSFUL LOGIN") {
        setUserData({
          name: result.user.userNames,
          email: result.user.user,
        });
        setLoggedIn(true);
        setErrorMessage("");
      } else {
        setIsLoading(false);
        switch (result.Message) {
          case "INVALID USER":
            setErrorMessage("Usuario inválido.");
            break;
          case "INVALID PASSWORD":
            setErrorMessage("Contraseña inválida.");
            break;
          default:
            setErrorMessage(result.Message);
            break;
        }
        //console.log(result.Message);
      }
    } catch (error) {
      setErrorMessage(
        "Ocurrió un problema al intentar iniciar sesión. Por favor, inténtalo nuevamente."
      );
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  function handleGoogleLogout(e) {
    setLoggedIn(false);
    setUserData(undefined);
  }

  async function LoginWK(user, pass) {
    let data = JSON.stringify({
      requestType: "loginUser",
      user: {
        user: user,
        pass: pass,
        hub: "CB000-Hub",
      },
    });
    try {
      let response = await axios.post(
        "https://api.workobot.com/dev/archibot",
        data
      );
      //console.log(response);
      return response.data;
    } catch (e) {
      //console.log(e);
      return { status: "Error", message: e };
    }
  }

  return (
    <>
      {!loggedIn ? (
        <div className="login-container">
          <h3 className="login__description">Inicio de sesión:</h3>
          <form className="login__form">
            <div className="login__input-group">
              <input
                type="text"
                className="login__input login__input--email"
                value={email}
                onChange={handleUserInputChange}
                placeholder="Email..."
                autoComplete="username"
                required
              ></input>
              <select
                className="login__input login__input--domain"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              >
                {loginDomains.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="login__input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="login__input login__input--password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Contraseña..."
                autoComplete="current-password"
                required
              ></input>
              <button
                type="button"
                className="login__toggle-password"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "Ocultar" : "Mostrar"}
              </button>
            </div>
            {errorMessage && (
              <div className="login__error-message">{errorMessage}</div>
            )}
          </form>
          {!isLoading ? (
            <div className="login__footer">
              <button
                type="submit"
                className="login__button"
                onClick={handleLogin}
              >
                Iniciar sesión
              </button>
            </div>
          ) : (
            "Validando usuario..."
          )}
        </div>
      ) : (
        <div className="signout flex-center">
          <button
            id="g_id_signout"
            className="flex-center"
            onClick={handleGoogleLogout}
          >
            <span className="login-capital">
              {userData.name
                ?.split(" ")
                .slice(0, 2)
                .map((word) => word.charAt(0))
                .join("")}
            </span>
            Cerrar Sesión
          </button>
        </div>
      )}
    </>
  );
}

export default LoginWorkobot;
