import React, { useState, useEffect } from "react";
import "./App.css";

export default function Loader({ myText }) {
  const [dots, setDots] = useState("");

  const myStyle = {
    myText: {
      fontSize: 18,
      fontWeight: "bold",
      color: "black",
      margin: "auto",
      padding: "20px 20px 0px 20px",
      /* border: "2px solid black", */
    },
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots(dots.length < 3 ? dots + "." : "");
    }, 1);

    return () => clearInterval(intervalId);
  }, [dots]);

  return <p style={myStyle.myText}>{myText + dots}</p>;
}
