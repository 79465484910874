import React, { useState, useEffect, Fragment, useRef } from "react";
import Select from "react-select";
import XLSX from "xlsx";
import ImgCard from "./ImageCard";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Resizer from "react-image-file-resizer";
import "./App.css";
import "./PlanoTipo.css";
import papaparse from "papaparse";
import ReactGA from "react-ga4";
import emailjs from "@emailjs/browser";
import WarnFormat from "./WarningFormat";
import WarnUnitsFormat from "./WarningUnitsFormat";
import TimeMe from "timeme.js";
import SendMetric from "./SendMetric";
import Loader from "./Loader";
import { RemoveAccents } from "./Utils";
import { Buffer } from "buffer";

const searchRootFolder = "arquitectura";
const searchImagesFolder = "imagenesplano";
const searchCuadroFolder = "cuadroplano";

//#region MY STYLES
const myStyles = {
  myDiv: {
    display: "block",
    marginTop: 10,
    padding: 20,
    backgroundColor: "#282c34",
  },
  myBtn: {
    fontSize: 30,
    fontWeight: "bold",
    color: "black",
    cursor: "pointer",
    padding: "20px",
  },
  myText: {
    fontSize: 20,
    fontWeight: "bold",
    color: "black",
    margin: "auto",
    padding: "20px 20px 10px 20px",
  },
  myText2: {
    fontSize: 16,
    //fontWeight: "bold",
    color: "black",
    textAlign: "center",
    paddingLeft: "10px",
    lineHeight: "25px",
  },
  myImg: {
    boxShadow: "0 0 20px 2px #9b9b9b",
    borderRadius: "10px",
    width: "100%",
    height: "auto",
    maxWidth: "500px",
  },
  mySentEmail: {
    boxShadow: "0 0 20px 2px #9b9b9b",
    width: "100%",
    height: "auto",
    backgroundColor: "#282c34",
    color: "white",
    marginTop: "25px",
    padding: "10px",
    fontSize: "22px",
    lineHeight: "15px",
  },
  mySentEmail2: {
    color: "white",
    fontSize: "14px",
  },
};

const dropdownStyle = {
  option: (provided, state) => ({
    ...provided,
    //borderBottom: "1px dashed #008c45",
    backgroundColor: state.isDisabled
      ? undefined
      : state.isSelected
      ? "#008c45"
      : state.isFocused
      ? "#008c45"
      : undefined,
    color: state.isDisabled
      ? undefined
      : state.isSelected
      ? "black"
      : state.isFocused
      ? "white"
      : undefined,
    //paddingLeft: "200px",
    //padding: 20,
    //padding: 20,
    cursor: "pointer",
  }),
  control: (provided) => ({
    ...provided,
    //width: 200,
    height: 40,
    boxShadow: "0px 3px 5px #a7a7a7",
    color: "#808080",
    paddingLeft: "60px",
    cursor: "pointer",
    borderRadius: "20px",
    borderStyle: "none",
    border: "2px",
    borderColor: "#008c45",
    "&:hover": {
      borderStyle: "solid",
      borderColor: "#008c45",
      color: "red",
    },
    "@media (max-width: 600px)": {
      paddingLeft: "0px",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    //const opacity = state.isDisabled ? 0.5 : 1;
    //const transition = "opacity 300ms";
    //color: "black",

    //return { ...provided, opacity, transition };
  }),
  menu: (provided, state) => ({
    ...provided,
    color: "#808080",
  }),
};
//#endregion

function PlanoTipo(userData) {
  //#region TIME ME
  TimeMe.initialize({
    currentPageName: "my-home-page", // current page
    idleTimeoutInSeconds: 90, // seconds
    // trackWhenUserLeavesPage: false,
    // trackWhenUserGoesIdle: false,
  });
  //#endregion

  //#region VARS
  const client = "CBB";

  //VARS UTILITIES
  const [showClg, setShowClg] = useState(false);
  const [soporteTecnico, setSoporteTecnico] = useState("");
  const [sentEmail, setSentEmail] = useState(false);

  //VARS BIM AUTH
  const [accessToken, setAccessToken] = useState({
    myToken: "",
    expire: "",
    timeStamp: 0,
  });

  //VARS PROYECTO
  const [buscandoData, setBuscandoData] = useState(false);
  const [warningProyectos, setWarningProyectos] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [showDropdownProyectos, setShowDropdownProyectos] = useState(false);
  const [dropdownProyectosOptions, setDropdownProyectosOptions] = useState([]);
  const [plantasList, setPlantasList] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});

  //VARS FICHA TECNICA - UNIDADES
  const [unitsList, setUnitsList] = useState([]);
  const [showDropdownUnidades, setShowDropdownUnidades] = useState(false);
  const [dropdownUnidadesOptions, setDropdownUnidadesOptions] = useState([]);

  //VARS MULTI FICHA DROPDOWN
  const [showMultiFichas, setShowMultiFichas] = useState(false);
  const [multiFichasOptions, setMultiFichasOptions] = useState([]);
  const [warnMultiFichas, setWarnMultiFichas] = useState("");
  const [progressMultiFichas, setProgressMultiFichas] = useState("");
  const [selectedFicha, setSelectedFicha] = useState("");
  const [showFichaFound, setShowFichaFound] = useState(false);

  //VARS SELECTED UNIT
  const [progressUnits, setProgressUnits] = useState("");
  const [warningUnits, setWarningUnits] = useState("");
  const [imageList, setImageList] = useState("");
  const [showImages, setShowImages] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [galleryImage, setGalleryImage] = useState("");
  const [selectedUnit, setSelectedUnit] = useState({});
  const cancelDownload = useRef(false);
  const downloadingImage = useRef(false);
  const waitingUnit = useRef(null);

  //VAR JSPDF
  const [showPdfExportBtn, setShowPdfExportBtn] = useState(false);
  const [showLoadingExportBtn, setShowLoadingExportBtn] = useState(false);
  const [sendMetric, setSendMetric] = useState(true);
  //#endregion

  //#region UTILS
  const toggleConsoleLogs = () => {
    setShowClg(!showClg);
  };

  const formatMessage = (myMessage) => {
    setWarningProyectos((warningProyectos) => [...warningProyectos, myMessage]);
  };

  const resetStates = () => {
    setImageList("");
    setWarningUnits("");
    setShowImages(false);
    setSelectedImages([]);
    setGalleryImage("");
    setShowPdfExportBtn(false);
    setSoporteTecnico("");
    setWarnMultiFichas("");
    setSentEmail(false);
    setWarningProyectos([]);
    setProgressMultiFichas("");
    setProgressUnits("");
    setSelectedUnit({});
    setSendMetric(true);
  };

  /* const whatsappClickToChat = () => {
    var showWarningProyectos = false;
    if (warningProyectos !== "") {
      if (soporteTecnico === warningProyectos) showWarningProyectos = false;
      else showWarningProyectos = true;
    }
    var errorMsg =
      soporteTecnico === ""
        ? "Error: N/A"
        : `Error: ${
            showWarningProyectos ? `${warningProyectos[0].key} %0a ` : ""
          }${soporteTecnico} `;
    errorMsg = errorMsg.replaceAll("•", "%0a •");
    var myFicha =
      selectedFicha === "" ? "" : ` %0a Ficha Técnica: ${selectedFicha}`;
    var myProject =
      selectedProject === undefined ? "" : `Proyecto: ${selectedProject.name}`;
    var tempMessage = ` Hola, mi nombre es ${userData.userData.givenName} ${userData.userData.familyName}. Necesito soporte técnico para la herramienta Plano Tipo. %0a ${myProject}${myFicha} %0a ${errorMsg}`;
    window.open(
      `https://wa.me/${process.env.REACT_APP_WHATSAPP_SOPORTE}?text=${tempMessage}`,
      "_blank"
    );
  }; */

  const sendEmail = () => {
    // console.log(warningProyectos);
    // console.log(warnMultiFichas);
    // console.log(warningUnits);
    // console.log(soporteTecnico);

    //PROYECTO
    var myProject =
      selectedProject === undefined
        ? ""
        : `<br><br>• Proyecto: ${selectedProject.name}`;

    //FICHA TÉCNICA
    var myFicha =
      selectedFicha === "" ? "" : `<br>• Cuadro de áreas: ${selectedFicha}`;

    //SELECTED UNIT
    var myUnit =
      selectedUnit.AGRUPACION === undefined
        ? ""
        : `<br>• Unidad: ${selectedUnit.AGRUPACION}`;

    //WARNINGS MULTIFICHAS
    var myWarnMultifichas = "";
    if (warnMultiFichas !== "") {
      myWarnMultifichas =
        "<br<br>Se encontraron los siguientes errores relacionados con el cuadro de áreas:";
      myWarnMultifichas += "<br>" + warnMultiFichas;
    }

    //WARNINGS PROYECTOS
    var myWarningsProyectos = "";
    if (warningProyectos.length > 0) {
      myWarningsProyectos =
        "<br><br>Se encontraron los siguientes errores en BIM 360:";
      warningProyectos.forEach((element) => {
        // console.log(element);
        myWarningsProyectos += "<br>" + element;
      });
    }

    //WARNINGS UNITS
    var myWarningsUnits = "";
    if (warningUnits.length > 0) {
      myWarningsUnits = `<br><br>No se encontraron las siguientes imágenes en BIM 360:`;
      warningUnits.forEach((element) => {
        // console.log(element);
        myWarningsUnits += "<br>• " + element;
      });
    }

    //SOPORTE TÉCNICO WORKOBOT
    var mySoporteTecnico = "";
    if (soporteTecnico !== "") {
      mySoporteTecnico = "<br>" + soporteTecnico;
    }

    var myMessage = `Solicito soporte para la herramienta Plano Tipo.${myProject}${myFicha}${myUnit}${myWarnMultifichas}${myWarningsProyectos}${myWarningsUnits}${mySoporteTecnico}`;

    var recipient = "";
    if (mySoporteTecnico !== "")
      recipient = process.env.REACT_APP_SOPORTEWORKOBOT;
    else recipient = process.env.REACT_APP_SOPORTECLIENTE; //"diseño@constructorabolivar.com"; //"juan.maldonado@workobot.com";

    var templateParams = {
      proyecto: selectedProject.name,
      message: myMessage,
      fromName: userData.userData.name,
      fromEmail: userData.userData.email,
      toEmail: recipient,
      copyTo: userData.userData.email,
    };

    emailjs
      .send(
        "service_10ullrh",
        "template_ytdg359",
        templateParams,
        "NE1G5oWNlaMDoGZbO"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          setSentEmail(true);
        },
        function (error) {
          console.log("FAILED...", error);
          setSoporteTecnico(
            "Error al enviar email de soporte técnico:\n" + error.text
          );
        }
      );
  };

  const isValidToken = async () => {
    // Catch Error: TypeError: Cannot read properties of undefined (reading '0')
    var cTs = Math.floor(Date.now() / 1000);
    // console.log(
    //   accessToken.expire +
    //     " | " +
    //     accessToken.timeStamp +
    //     "=" +
    //     (accessToken.expire + accessToken.timeStamp) +
    //     " | " +
    //     cTs
    // );
    if (cTs < accessToken.timeStamp + accessToken.expire) {
      return accessToken.myToken;
    } else {
      /* #region Request Params */
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      //New Auth V2 --------------------------------------------
      const encondedAuth = Buffer.from(
        `${process.env.REACT_APP_FORGE_CLIENT_ID}:${process.env.REACT_APP_FORGE_CLIENT_SECRET}`
      ).toString("base64");
      //console.log(encondedAuth);
      myHeaders.append("Authorization", `Basic ${encondedAuth}`);

      var urlencoded = new URLSearchParams();
      // urlencoded.append("client_id", process.env.REACT_APP_FORGE_CLIENT_ID);
      // urlencoded.append(
      //   "client_secret",
      //   process.env.REACT_APP_FORGE_CLIENT_SECRET
      // );
      urlencoded.append("grant_type", "client_credentials");
      urlencoded.append("scope", "data:read");
      urlencoded.append("scope", "data:search");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
      /* #endregion */
      /* #region ASYNC METHOD */
      try {
        const response = await fetch(
          //"https://developer.api.autodesk.com/authentication/v1/authenticate",
          "https://developer.api.autodesk.com/authentication/v2/token",
          requestOptions
        );
        const json = await response.json();
        setAccessToken({
          myToken: json.access_token,
          expire: json.expires_in,
          timeStamp: Math.floor(Date.now() / 1000),
        });
        console.log("New Token");
        return json.access_token;
      } catch (error) {
        console.error("Catch Error: ", error);
        setSoporteTecnico(
          `Error de conexión con servidor BIM 360, intenta nuevamente.\nAutenticando:\n${error}`
        );
      }
      /* #endregion */
    }
  };
  //#endregion

  //#region 01 - GET PROJECTS LIST ---------------------------------------------------------------------------------------------------------
  useEffect(() => {
    /*#region TEST MESSAGES*/
    //console.log(userData);
    // formatMessage(`• No se encontró la carpeta /01 - ARQUITECTURA`);
    // formatMessage(`• No se encontró la carpeta /02 - TEST`);
    //setSoporteTecnico("Catch Error - Obteniendo Hubs");
    //return;
    /*#endregion*/

    //console.log(userData.userData.givenName);
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    ReactGA.send({ hitType: "pageview", page: "/PlanoTipo" });

    //#region BIM360 - AUTHENTICATE & GET ACCESS TOKEN
    const bimAuthGetAccessToken = async () => {
      /* #region Request Params */
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      //New Auth V2 --------------------------------------------
      const encondedAuth = Buffer.from(
        `${process.env.REACT_APP_FORGE_CLIENT_ID}:${process.env.REACT_APP_FORGE_CLIENT_SECRET}`
      ).toString("base64");
      //console.log(encondedAuth);
      myHeaders.append("Authorization", `Basic ${encondedAuth}`);

      var urlencoded = new URLSearchParams();
      // urlencoded.append("client_id", process.env.REACT_APP_FORGE_CLIENT_ID);
      // urlencoded.append(
      //   "client_secret",
      //   process.env.REACT_APP_FORGE_CLIENT_SECRET
      // );
      urlencoded.append("grant_type", "client_credentials");
      urlencoded.append("scope", "data:read");
      urlencoded.append("scope", "data:search");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
      /* #endregion */

      /* #region ASYNC METHOD */
      try {
        const response = await fetch(
          //"https://developer.api.autodesk.com/authentication/v1/authenticate",
          "https://developer.api.autodesk.com/authentication/v2/token",
          requestOptions
        );
        const json = await response.json();
        //console.log(json);
        setAccessToken({
          myToken: json.access_token,
          expire: json.expires_in,
          timeStamp: Math.floor(Date.now() / 1000),
        });
        // if (showClg) console.log("MyToken: \n" + json.access_token);
        bimGetHub(json.access_token);
      } catch (error) {
        console.error("Catch Error: ", error);
        setSoporteTecnico(
          `Error de conexión con servidor BIM 360, intenta nuevamente.\nAutenticando:\n${error}`
        );
      }
      /* #endregion */
    };
    //#endregion

    //#region BIM360 - GET HUB
    const bimGetHub = (myToken) => {
      /* #region  Request Params */
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + myToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };
      /* #endregion */

      /* #region  THEN METHOD */
      fetch(
        "https://developer.api.autodesk.com/project/v1/hubs",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log("Hubs: \n" + JSON.stringify(data, null, 4));
          // if (showClg) console.log("Hub id: \n" + data.data[0].id);
          bimGetProjectsList(data.data[0].id, requestOptions);
        })
        .catch((error) => {
          console.error("Catch Error: ", error);
          setSoporteTecnico(
            `Error de conexión con servidor BIM 360, intenta nuevamente.\nObteniendo Hubs:\n ${error}`
          );
        });
      /* #endregion */
    };
    //#endregion

    //#region BIM360 - GET PROJECT LIST
    const bimGetProjectsList = async (myHubId, requestOptions) => {
      try {
        const response = await fetch(
          `https://developer.api.autodesk.com/project/v1/hubs/${myHubId}/projects`,
          requestOptions
        );
        const json = await response.json();

        var myProjectsList = [];
        var myDropdownProyectos = [];
        for (let i = 0; i < json.data.length; i++) {
          //Only add Projects not incluiding substring...
          if (
            !json.data[i].attributes.name.includes("00-") &&
            !json.data[i].attributes.name.includes("TEST") &&
            !json.data[i].attributes.name.includes("Component Library") &&
            !json.data[i].attributes.name.includes("-URB_")
          ) {
            const element = {
              name: json.data[i].attributes.name,
              id: json.data[i].id,
              rootFolder: json.data[i].relationships.rootFolder.data.id,
            };
            const itemDropdown = {
              value: json.data[i].attributes.name,
              label: json.data[i].attributes.name,
            };
            //console.log(element);
            myProjectsList.push(element);
            myDropdownProyectos.push(itemDropdown);
          }
        }
        myProjectsList.sort((a, b) => a.name.localeCompare(b.name));
        myDropdownProyectos.sort((a, b) => a.value.localeCompare(b.value));
        //console.log(JSON.stringify(myProjectsList, null, 4));
        //console.log(JSON.stringify(myDropdownProyectos, null, 4));
        // if (showClg)
        //   console.log("Project Count: " + myDropdownProyectos.length);

        setProjectsList(myProjectsList);
        setDropdownProyectosOptions(myDropdownProyectos);
        setShowDropdownProyectos(true);
      } catch (error) {
        console.error(`Catch Error - Obteniendo Proyectos: ${error}`);
        setSoporteTecnico(
          `Error de conexión con servidor BIM 360, intenta nuevamente.\nObteniendo Proyectos:\n${error}`
        );
      }
    };
    //#endregion

    bimAuthGetAccessToken();
  }, []);
  //#endregion ------------------------------------------------------------------------------------------------------------------------------

  //#region 02 - GET FICHA TECNICA FROM SELECTED PROJECT -----------------------------------------------------------------------------------

  //#region 02-A - GET /ProjectFiles ID
  const bimNavigateToProjectFiles = async (mySelectedProject) => {
    ReactGA.send({ hitType: "pageview", page: "/" + mySelectedProject.name });

    //#region ADD AWS METRIC
    const myMetric = JSON.stringify({
      requestType: "newMetric",
      metric: {
        client: client,
        user: userData.userData.email,
        metric: "selectedProject",
        value1: mySelectedProject.name,
        value2: "",
      },
    });
    SendMetric(myMetric);
    //#endregion

    setBuscandoData(true);

    //Validate Token
    const myToken = await isValidToken();

    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    /* #region  ASYNC METHOD */
    try {
      const response = await fetch(
        `https://developer.api.autodesk.com/data/v1/projects/${mySelectedProject.id}/folders/${mySelectedProject.rootFolder}/contents?filter[attributes.name]=Project%20Files`,
        requestOptions
      );
      const json = await response.json();
      //console.log("Project Files: \n" + JSON.stringify(json));
      if (json.data[0].id !== undefined) {
        if (showClg)
          console.log("Found Folder: " + json.data[0].attributes.name);
        bimNavigateToDocsDiseno(mySelectedProject, json.data[0].id, myToken);
      } else {
        if (showClg) console.log("No se encontró la carpeta /ProjectFiles");
        formatMessage("• No se encontró la carpeta /ProjectFiles");
      }
    } catch (error) {
      console.error("Catch Error: ", error);
      setSoporteTecnico(
        `Error de conexión con servidor BIM 360, intenta nuevamente.\nBuscando contenido en carpeta /ProjectFiles:\n${error}`
      );
    }
    setBuscandoData(false);
    /* #endregion */
  };
  //#endregion

  //#region 02-B - GET CONTENTS OF /ProjectFiles & GET ID OF /*DISEÑO
  const bimNavigateToDocsDiseno = async (
    mySelectedProject,
    folderId,
    myToken
  ) => {
    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    /* #region  ASYNC METHOD */
    try {
      const response = await fetch(
        `https://developer.api.autodesk.com/data/v1/projects/${mySelectedProject.id}/folders/${folderId}/contents`, //?filter[attributes.name]=04-DOCS_DISEÑO`,
        requestOptions
      );
      const json = await response.json();
      //console.log("CONTENTS PROJECT FILES: \n" + JSON.stringify(json));

      let myFolder;
      for (let i = 0; i < json.data.length; i++) {
        if (
          RemoveAccents(json.data[i].attributes.displayName).includes(
            searchRootFolder
          )
        ) {
          myFolder = json.data[i];
          break;
        }
      }

      if (myFolder !== undefined) {
        bimNavigateToFichaTecnica(mySelectedProject, myFolder.id, myToken);
        if (showClg) {
          console.log(
            `Found Folder:  ${myFolder.attributes.name} | ${myFolder.id}`
          );
        }
      } else {
        //console.log(`No se encontró la carpeta /${searchFolder}`);
        formatMessage(`• No se encontró la carpeta /${searchRootFolder}`);
      }
    } catch (error) {
      console.error("Catch Error: ", error);
      setSoporteTecnico(
        `Error de conexión con servidor BIM 360, intenta nuevamente.\nObteniendo contenido de /ProjectFiles\n${error}`
      );
    }
    /* #endregion */
  };
  //#endregion

  //#region 02-C - GET CONTENTS OF /*DISEÑO & GET ID OF /*FICHA & GET ID OF /*AMBIENTACIONES
  const bimNavigateToFichaTecnica = async (
    mySelectedProject,
    folderId,
    myToken
  ) => {
    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    /* #region  ASYNC METHOD */
    try {
      const response = await fetch(
        `https://developer.api.autodesk.com/data/v1/projects/${mySelectedProject.id}/folders/${folderId}/contents`, //?filter[attributes.name]=D3-FICHA%20TÉCNICA`,
        requestOptions
      );
      const json = await response.json();
      //console.log("CONTENTS DISEÑO: \n" + JSON.stringify(json));

      var myFolderCuadro;
      var myFolderImages;
      var strWarning = "";
      for (let i = 0; i < json.data.length; i++) {
        //#region Get ID of IMAGES FOLDER ----------------------------
        if (myFolderImages === undefined) {
          if (
            RemoveAccents(json.data[i].attributes.displayName).includes(
              searchImagesFolder
            )
          ) {
            myFolderImages = json.data[i];
            if (showClg) {
              console.log(
                `Found Folder: ${myFolderImages.attributes.displayName} | ${myFolderImages.id}`
              );
            }
          }
        }
        //#endregion Get ID of IMAGES FOLDER ----------------------------

        //#region Get CUADRO FOLDER object ---------------------------
        if (myFolderCuadro === undefined) {
          if (
            RemoveAccents(json.data[i].attributes.displayName).includes(
              searchCuadroFolder
            )
          ) {
            myFolderCuadro = json.data[i];
            if (showClg) {
              console.log(
                `Found Folder: ${myFolderCuadro.attributes.displayName} | ${myFolderCuadro.id}`
              );
            }
          }
        }
        //#endregion Get CUADRO FOLDER object ---------------------------
      }

      //#region Show Warnings and Return on Error
      if (myFolderImages === "") {
        strWarning = `• No se encontró una carpeta con las imágenes de Plano Tipo. La carpeta debe contener en el nombre: "${searchImagesFolder}"`;
      }
      if (myFolderCuadro === undefined) {
        let missingCuadro = `• No se encontró la carpeta con el cuadro de Plano Tipo. La carpeta debe contener en el nombre: "${searchCuadroFolder}"`;
        strWarning =
          strWarning === "" ? missingCuadro : strWarning + `\n${missingCuadro}`;
      }
      if (strWarning !== "") {
        formatMessage(strWarning);
        return;
      }
      //#endregion

      //#region Process Ambientaciones & Ficha Tecnica
      var ambientaciones = await bimContentsOfAmbientaciones(
        mySelectedProject,
        myFolderImages.id,
        myFolderImages.attributes.displayName,
        myToken
      );
      var fichaContents = await bimContentsOfFichaTecnica(
        mySelectedProject,
        myFolderCuadro.id,
        myFolderCuadro.attributes.displayName,
        myToken
      );

      var showingSoporte = false;

      if (ambientaciones !== "") {
        formatMessage(`• ${ambientaciones}`);
        showingSoporte = true;
      }
      if (fichaContents !== "" && !fichaContents.includes("múltiples")) {
        formatMessage(`• ${fichaContents}`);
        showingSoporte = true;
      }

      if (fichaContents.includes("múltiples") && !showingSoporte) {
        setShowMultiFichas(true);
      }

      //#endregion
    } catch (error) {
      console.error("Catch Error: ", error);
      setSoporteTecnico(
        `Error de conexión con servidor BIM 360, intenta nuevamente.\nObteniendo contenido de carpeta "${searchRootFolder}":\n${error}`
      );
    }
    /* #endregion */
  };
  //#endregion

  //#region 02-D1 - GET CONTENTS OF /*AMBIENTACIONES & FIND .JPGs
  const bimContentsOfAmbientaciones = async (
    mySelectedProject,
    folderId,
    folderName,
    myToken
  ) => {
    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    try {
      /* #region FETCH */
      const response = await fetch(
        `https://developer.api.autodesk.com/data/v1/projects/${mySelectedProject.id}/folders/${folderId}/contents`,
        requestOptions
      );
      const json = await response.json();
      if (showClg) console.log(`CONTENTS of ${folderName}: \n`, json);
      /* #endregion */

      //#region Warning if Data = 0 & return
      if (json.data.length === 0) {
        return `No se encontraron archivos en la carpeta ${folderName}.`;
      }
      //#endregion

      //#region Get contents of /*...Planos
      else {
        var plantasObjs = [];
        for (let i = 0; i < json.data.length; i++) {
          if (
            json.data[i].attributes.displayName.includes(".jpg") ||
            json.data[i].attributes.displayName.includes(".png")
          ) {
            const myObj = {
              name: json.data[i].attributes.displayName,
              id: json.data[i].id,
              image: ["dummy"],
              storageId: json.included[i].relationships.storage.data.id,
            };
            plantasObjs.push(myObj);
          }
          //console.log("Plantas: \n" + JSON.stringify(plantasObjs, null, 4));
        }
        if (plantasObjs.length > 0) {
          setPlantasList(plantasObjs);
          return "";
        } else {
          return `No se encontraron imágenes en la carpeta ${folderName}.`;
        }
      }
      //#endregion
    } catch (error) {
      console.error("Catch Error: ", error);
      return `Catch Error - Obteniendo contenido de /${folderName}: ` + error;
    }
  };
  //#endregion

  //#region 02-D2 - GET CONTENTS OF /*FICHA & FIND *ficha*.xlsx
  const bimContentsOfFichaTecnica = async (
    mySelectedProject,
    folderId,
    folderName,
    myToken
  ) => {
    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    /* #region  ASYNC METHOD */
    try {
      //#region FETCH
      const response = await fetch(
        `https://developer.api.autodesk.com/data/v1/projects/${mySelectedProject.id}/folders/${folderId}/contents`,
        requestOptions
      );
      const json = await response.json();
      if (showClg) console.log(`CONTENTS of ${folderName}: \n`, json);
      //#endregion

      //#region Find all FICHA files
      if (json.data.length > 0) {
        var myFilesWithXlsX = [];
        for (let i = 0; i < json.data.length; i++) {
          if (json.data[i].type === "folders") json.data.splice(i, 1); //Removes the object from the array if its type is folder.
        }
        for (let i = 0; i < json.data.length; i++) {
          let myFile = json.data[i].attributes.displayName.toLowerCase();
          if (myFile.includes(".csv") || myFile.includes(".xlsx")) {
            var myJsonObj = json.data[i];
            myJsonObj.url = json.included[i].relationships.storage.data.id;
            myFilesWithXlsX.push(myJsonObj);
          }
        }
      } else {
        return `No se encontraron archivos en la carpeta ${folderName}`;
      }

      //   console.log(
      //     "EXCELS: " + myXlsx.length + "\n" + JSON.stringify(myXlsx, null, 4)
      //   );
      //#endregion

      var myFichas = [];
      var myFicha = [];
      //#region FOUND >1 FICHA - SHOW MULTI FICHA DROPDOWN
      if (myFilesWithXlsX.length > 1) {
        //#region OLD - AUTO FIND & SELECT FICHA - DEPRICATED
        /*
        //#region Find Files with "Ficha" in name
        for (let i = 0; i < myFilesWithXlsX.length; i++) {
          if (
            myFilesWithXlsX[i].attributes.displayName
              .toLowerCase()
              .includes("ficha")
          ) {
            myFichas.push(myFilesWithXlsX[i]);
          }
        }
        //#endregion

        //#region Select Ficha by lastModifiedTime
        if (myFichas.length === 0) {
          setWarningProyectos(formatMessage("Cuadro de áreas not found."));
          return;
        } else {
          if (myFichas.length > 0) {
            myFichas.sort((a, b) => {
              return (
                new Date(a.attributes.lastModifiedTime) -
                new Date(b.attributes.lastModifiedTime)
              );
            });
            for (let i = 0; i < myFichas.length; i++) {
              console.log(
                `Found Ficha[${i}]: ${myFichas[i].attributes.displayName} | ${myFichas[i].attributes.lastModifiedTime}`
              );
            }
            myFicha.push(myFichas[myFichas.length - 1]);
          } else {
            myFicha = myFichas;
          }
        }
        //#endregion
        */
        //#endregion

        for (let i = 0; i < myFilesWithXlsX.length; i++) {
          const item = {
            value: myFilesWithXlsX[i].attributes.displayName,
            label: myFilesWithXlsX[i].attributes.displayName,
            id: myFilesWithXlsX[i].id,
            storageId: myFilesWithXlsX[i].url,
          };
          myFichas.push(item);
        }
        setProgressMultiFichas(
          `Se encontraron múltiples cuadros de áreas en BIM 360:`
        );
        setMultiFichasOptions(myFichas);
        return `Se encontraron múltiples Cuadros de áreas.`;
      }
      //#endregion
      //#region FOUND ONLY 1 FICHA
      else if (myFilesWithXlsX.length === 1) {
        myFicha[0] = myFilesWithXlsX[0];
        setSelectedFicha(myFicha[0].attributes.displayName);
        setShowFichaFound(true);
        setWarningProyectos("");
        if (showClg) {
          console.log("Ficha Técnica: ", myFicha[0]);
        }
        bimHrefFichaTecnica(mySelectedProject, myFicha[0].id, myToken);
        setProgressMultiFichas("Cargando Cuadro de áreas...");
        return "";
      }
      //#endregion
      //#region NO FICHAS FOUND
      else {
        return `No se encontraron archivos .csv o .xlsx en la carpeta ${folderName}.`;
      }
      //#endregion
    } catch (error) {
      console.error(
        `Catch Error - Obteniendo contenido de ${folderName}: ${error}`
      );
      return `Catch Error - Obteniendo contenido de ${folderName}: ${error}`;
    }
    /* #endregion */
  };
  //#endregion

  //#region 02-E - GET HREF OF *ficha*.xlsx
  const bimHrefFichaTecnica = async (mySelectedProject, myFichaId) => {
    //Validate Token
    const myToken = await isValidToken();

    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    /* #region  ASYNC METHOD */
    try {
      const response = await fetch(
        `https://developer.api.autodesk.com/data/v1/projects/${mySelectedProject.id}/items/${myFichaId}`,
        requestOptions
      );
      const json = await response.json();
      if (showClg) {
        console.log(
          "Ficha Técnica Endpoint: \n" +
            json.included[0].relationships.storage.meta.link.href
        );
      }
      setSelectedFicha(json.included[0].attributes.displayName);
      var storageId = json.included[0].relationships.storage.data.id;
      //console.log("STORAGE ID:" + storageId);
      bimDownloadFichaTecnica(
        json.included[0].relationships.storage.meta.link.href,
        myToken,
        json.included[0].attributes.displayName,
        storageId
      );
    } catch (error) {
      console.error("Catch Error: ", error);
      setSoporteTecnico(
        `Error de conexión con servidor BIM 360, intenta nuevamente.\nObteniendo URL de Cuadro de áreas:\n${error}`
      );
    }
    /* #endregion */
  };
  //#endregion

  //#region 02-F - DOWNLOAD *ficha*.xlsx
  const bimDownloadFichaTecnica = async (
    myEndpoint,
    myToken,
    myFichaName,
    myStorageId
  ) => {
    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    //#region GENERATE SIGNED S3 URL
    //Se agrega myStorageId por que Autodesk cambió el API y ahora se debe generar una URL para descargar desde un bucket en AWS S3
    if (myStorageId !== undefined) {
      myEndpoint = await Utils_GenerateS3URL(myStorageId, myToken);
      if (myEndpoint !== null) {
        //console.log(myEndpoint);
        requestOptions = {};
        requestOptions = { method: "GET" };
      } else {
        return;
      }
    }
    //#endregion

    /* #region  ASYNC METHOD */
    try {
      //console.log(myFichaName);
      let myData = undefined;
      //console.log("MY ENDPOINT:" + myEndpoint);
      const response = await fetch(`${myEndpoint}`, requestOptions);

      //#region PARSE XLSX
      if (myFichaName.includes(".xlsx")) {
        let myArrayBuffer = await response.arrayBuffer();
        let workbook = XLSX.read(myArrayBuffer);
        var sheetsNames = workbook.SheetNames;
        var sheeetNamesParsed = sheetsNames.map((x) =>
          x.replace(/\s+/g, "").toLowerCase()
        );
        if (showClg) console.log(JSON.stringify(sheeetNamesParsed, null, 4));
        const sheetIndex = sheeetNamesParsed.indexOf("planotipo2");
        //console.log(sheetIndex);
        if (sheetIndex !== -1) {
          myData = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetsNames[sheetIndex]]
          );

          showUnitsDropdown(myData);
          setWarnMultiFichas("");
          if (showClg) console.log(myData);
          //console.log(myData[0]["UBICA CIÓN"]);
          //console.log(myData[0].AGRUPACION);
        } else {
          setWarnMultiFichas(
            `• El archivo ${myFichaName} no tiene hoja Plano Tipo.`
          );
          setWarningUnits("");
        }
      }
      //#endregion

      //#region PARSE CSV
      else if (myFichaName.includes(".csv")) {
        let myBlobResponse = await response.blob();
        papaparse.parse(myBlobResponse, {
          header: true,
          complete: (results) => {
            if (results.data[0].AGRUPACION !== undefined) {
              myData = results.data;
              setWarnMultiFichas("");
              showUnitsDropdown(myData);
            } else {
              setWarnMultiFichas(
                `• El archivo ${myFichaName} no es una hoja Plano Tipo del cuadro de áreas.`
              );
              setWarningUnits("");
            }
          },
        });
      }
      setProgressMultiFichas("");
      //#endregion
    } catch (error) {
      console.error(`Catch Error - Descargando Cuadro de áreas: ${error}`);
      setSoporteTecnico(
        `Error de conexión con servidor BIM 360, intenta nuevamente.\nDescargando Cuadro de áreas:\n${error}`
      );
    }
    /* #endregion */
  };
  //#endregion

  //#endregion ------------------------------------------------------------------------------------------------------------------------------

  //#region 03 - UNITS DROPDOWN - GET IMAGES -----------------------------------------------------------------------------------------------

  //#region 03-A - SHOW UNIT DROPDOWN
  const showUnitsDropdown = (myData) => {
    setUnitsList(myData);
    try {
      var myOptions = [];
      for (let i = 0; i < myData.length; i++) {
        if (myData[i].AGRUPACION !== undefined) {
          const itemDropdown = {
            value: myData[i].AGRUPACION,
            label: myData[i].AGRUPACION,
          };
          //console.log(itemDropdown);
          if (itemDropdown.value !== "" && itemDropdown.value !== " ")
            myOptions.push(itemDropdown);
        }
      }
      setDropdownUnidadesOptions(myOptions);
      setShowDropdownUnidades(true);
      setWarningUnits("");
    } catch (error) {
      setWarnMultiFichas([`Error de carga de Cuadro de Áreas:\n${error}`]);
    }
  };
  //#endregion

  //#region 03-B - SELECT UNIT & SHOW IMAGES LIST
  const selectUnitDropdown = async (myUnit) => {
    setProgressUnits("Descargando imágenes");
    setSelectedUnit(myUnit);
    //console.log("Plantas folder id: " + plantasFolderId);
    //console.log("Plantas: \n" + JSON.stringify(plantasList, null, 4));
    var mySelectedImages = [];

    if (showClg) console.log("My Unit: ", myUnit);
    if (showClg) console.log("Plantas List: ", plantasList);

    //#region ARRAYS
    let macroArray = [];
    if (
      myUnit.MACRO !== undefined &&
      myUnit.MACRO !== "" &&
      myUnit.MACRO !== " "
    ) {
      macroArray = myUnit.MACRO.split(",");
    }

    let urbanoArray = [];
    if (
      myUnit.URBANO !== undefined &&
      myUnit.URBANO !== "" &&
      myUnit.URBANO !== " "
    ) {
      urbanoArray = myUnit.URBANO.split(",");
    }

    //Los parámetros de parqueaderos y depósitos se unifican en el array de parqueaderos
    let prqArray = [];
    if (
      myUnit.PARQUEADERO !== undefined &&
      myUnit.PARQUEADERO !== "" &&
      myUnit.PARQUEADERO !== " "
    ) {
      let myParqueaderos = myUnit.PARQUEADERO.split(",").map((item) => {
        return item.trim();
      });

      myParqueaderos.forEach((parq) => {
        if (prqArray.indexOf(parq) < 0) prqArray.push(parq);
      });
    }

    if (
      myUnit.DEPOSITO !== undefined &&
      myUnit.DEPOSITO !== "" &&
      myUnit.DEPOSITO !== " "
    ) {
      let deposito1 = myUnit.DEPOSITO.split(",").map((item) => {
        return item.trim();
      });
      deposito1.forEach((element) => {
        if (prqArray.indexOf(element) < 0) prqArray.push(element);
      });
      prqArray = [...new Set(prqArray)]; //Elimina duplicados
    }

    if (
      myUnit["DEPOSITO 2"] !== undefined &&
      myUnit["DEPOSITO 2"] !== "" &&
      myUnit["DEPOSITO 2"] !== " "
    ) {
      let deposito2 = myUnit["DEPOSITO 2"].split(",").map((item) => {
        return item.trim();
      });
      deposito2.forEach((element) => {
        if (prqArray.indexOf(element) < 0) prqArray.push(element);
      });
      prqArray = [...new Set(prqArray)]; //Elimina duplicados
    }

    let torreArray = [];
    if (
      myUnit.TORRE !== undefined &&
      myUnit.TORRE !== "" &&
      myUnit.TORRE !== " "
    ) {
      torreArray = myUnit.TORRE.split(",");
    }

    let planoArray = [];
    if (
      myUnit.PLANO !== undefined &&
      myUnit.PLANO !== "" &&
      myUnit.PLANO !== " "
    ) {
      planoArray = myUnit.PLANO.split(",");
    }
    //#endregion

    let imagesNotfound = [];
    //#region MACRO
    if (macroArray.length > 0) {
      for (let i = 0; i < macroArray.length; i++) {
        const element = macroArray[i];
        const elementTrim = element.trim(); //Trims whitespace from start and end
        let match;
        for (let j = 0; j < plantasList.length; j++) {
          const item = plantasList[j];
          if (
            item.name
              .substring(0, item.name.lastIndexOf("."))
              .toLowerCase()
              .replace(/\s/g, "") ===
            elementTrim.toLowerCase().replace(/\s/g, "")
          ) {
            match = item;
            mySelectedImages.push(item);
            break;
          }
        }
        if (match === undefined) {
          imagesNotfound.push(elementTrim); // = `${imagesNotfound}\n• ${elementTrim}`;
        }
        if (showClg) console.log(match);
      }
    }
    //#endregion
    //#region URBANO
    if (urbanoArray.length > 0) {
      for (let i = 0; i < urbanoArray.length; i++) {
        const element = urbanoArray[i];
        const elementTrim = element.trim(); //Trims whitespace from start and end
        let match;
        for (let j = 0; j < plantasList.length; j++) {
          const item = plantasList[j];
          if (
            item.name
              .substring(0, item.name.lastIndexOf("."))
              .toLowerCase()
              .replace(/\s/g, "") ===
            elementTrim.toLowerCase().replace(/\s/g, "")
          ) {
            match = item;
            mySelectedImages.push(item);
            break;
          }
        }
        if (match === undefined) {
          imagesNotfound.push(elementTrim); //imagesNotfound = `${imagesNotfound}\n• ${elementTrim}`;
        }
        if (showClg) console.log(match);
      }
    }
    //#endregion
    //#region PARQUEADERO
    if (prqArray.length > 0) {
      for (let i = 0; i < prqArray.length; i++) {
        const element = prqArray[i];
        const elementTrim = element.trim(); //Trims whitespace from start and end
        let match;
        for (let j = 0; j < plantasList.length; j++) {
          const item = plantasList[j];
          if (
            item.name
              .substring(0, item.name.lastIndexOf("."))
              .toLowerCase()
              .replace(/\s/g, "") ===
            elementTrim.toLowerCase().replace(/\s/g, "")
          ) {
            match = item;
            mySelectedImages.push(item);
            break;
          }
        }
        if (match === undefined) {
          imagesNotfound.push(elementTrim); //imagesNotfound = `${imagesNotfound}\n• ${elementTrim}`;
        }
        if (showClg) console.log(match);
      }
    }
    //#endregion
    //#region TORRE
    if (torreArray.length > 0) {
      for (let i = 0; i < torreArray.length; i++) {
        const element = torreArray[i];
        const elementTrim = element.trim(); //Trims whitespace from start and end
        let match;
        for (let j = 0; j < plantasList.length; j++) {
          const item = plantasList[j];
          if (
            item.name
              .substring(0, item.name.lastIndexOf("."))
              .toLowerCase()
              .replace(/\s/g, "") ===
            elementTrim.toLowerCase().replace(/\s/g, "")
          ) {
            match = item;
            mySelectedImages.push(item);
            break;
          }
        }
        if (match === undefined) {
          imagesNotfound.push(elementTrim); //imagesNotfound = `${imagesNotfound}\n• ${elementTrim}`;
        }
        if (showClg) console.log(match);
      }
    }
    //#endregion
    //#region PLANO
    if (planoArray.length > 0) {
      for (let i = 0; i < planoArray.length; i++) {
        const element = planoArray[i];
        const elementTrim = element.trim(); //Trims whitespace from start and end
        if (showClg)
          console.log(
            "BUSCANDO PLANO: ",
            elementTrim.toLowerCase().replace(/\s/g, "")
          );
        let match = undefined;
        for (let j = 0; j < plantasList.length; j++) {
          const item = plantasList[j];
          if (showClg)
            console.log(
              item.name
                .substring(0, item.name.lastIndexOf("."))
                .toLowerCase()
                .replace(/\s/g, "")
            );
          if (
            item.name
              .substring(0, item.name.lastIndexOf("."))
              .toLowerCase()
              .replace(/\s/g, "") ===
            elementTrim.toLowerCase().replace(/\s/g, "")
          ) {
            match = item;
            mySelectedImages.push(item);
            break;
          }
        }
        if (match === undefined) {
          imagesNotfound.push(elementTrim); //imagesNotfound = `${imagesNotfound}\n• ${elementTrim}`;
        }
        if (showClg) console.log("MATCH", match);
      }
    }
    //#endregion

    if (imagesNotfound.length === 0 && mySelectedImages.length > 0) {
      if (showClg) console.log("Selected Images: \n", mySelectedImages);

      var something = (function () {
        return async function () {
          if (!downloadingImage.current) {
            downloadingImage.current = true;
            await manageImageDownloads(mySelectedImages, myUnit);
            if (showClg)
              console.log("MANAGE DOWNLOADS DONE " + myUnit.AGRUPACION);
            downloadingImage.current = false;
            if (waitingUnit.current !== null) {
              if (showClg)
                console.log(
                  "WaitingUnit: " + waitingUnit.current.AGRUPACION,
                  waitingUnit.current
                );
              selectUnitDropdown(waitingUnit.current);
            }
            waitingUnit.current = null;
          } else {
            if (showClg)
              console.log("Waiting for Manage Downloads " + myUnit.AGRUPACION);
            waitingUnit.current = myUnit;
            cancelDownload.current = true;
          }
        };
      })();
      something();
      //
    } else {
      var mySoporte = "";
      if (imagesNotfound.length > 0) mySoporte = imagesNotfound;
      else if (mySelectedImages.length === 0) {
        imagesNotfound.push(
          `La unidad ${myUnit.AGRUPACION} no tiene imágenes asignadas en el cuadro de áreas, en la hoja "Plano Tipo".`
        );
        mySoporte = imagesNotfound;
      }
      setWarningUnits(mySoporte);
      setProgressUnits("");
    }
  };
  //#endregion

  //#region 03-C - DOWNLOAD IMAGES
  const manageImageDownloads = async (mySelectedImages, mySelectedUnit) => {
    if (showClg)
      console.log(
        `START MANAGE DOWNLOADS: ${mySelectedUnit.AGRUPACION}\n`,
        mySelectedImages
      );
    // refSelectedUnit.current = selectedUnit;

    let tempSelectedImages = [];
    for (let i = 0; i < mySelectedImages.length; i++) {
      if (showClg) console.log("LOOP[" + i + "] " + mySelectedUnit.AGRUPACION);
      const downloadResponse = await downloadImage(
        mySelectedImages[i],
        i,
        mySelectedUnit
      );
      if (downloadResponse === false || cancelDownload.current === true) {
        if (showClg) console.log("EXIT 🤘 " + mySelectedUnit.AGRUPACION);
        cancelDownload.current = false;
        return false;
      } else {
        if (showClg) console.log("DOWNLOAD RESPONSE: ", downloadResponse);
        setGalleryImage(downloadResponse.image);
        //#region CACHE IMAGE --------------------------------------------------------
        const newArray = downloadedImages;
        // console.log(downloadResponse.name);
        const object = downloadedImages.find(
          (item) => item.name === downloadResponse.name
        );
        if (!object) {
          newArray.push(downloadResponse);
          setDownloadedImages(newArray); //CACHE IMAGE
        }
        //#endregion CACHE IMAGE -----------------------------------------------------
        tempSelectedImages.push(downloadResponse);
      }
    }
    if (showClg) console.log("DOWNLOAD DONE 👌");
    setSelectedImages(tempSelectedImages);
    setWarningUnits("");
    setGalleryImage("");
    setProgressUnits("");
    setShowImages(true);
    setShowPdfExportBtn(true);
    return null;
  };

  const [downloadedImages, setDownloadedImages] = useState([]);

  const downloadImage = async (
    mySelectedImage,
    mySelectedImageIndex,
    mySelectedUnit
  ) => {
    if (showClg)
      console.log(
        "DOWNLOADING [" +
          mySelectedImageIndex +
          "] " +
          mySelectedUnit.AGRUPACION,
        mySelectedImage
      );
    //Validate Token
    const myToken = await isValidToken();

    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    var myItemUrl;
    try {
      if (mySelectedImage.storageId !== undefined) {
        //#region GENERATE SIGNED S3 URL (NEW API SEP30/2022)
        //Se agrega myStorageId por que Autodesk cambió el API y ahora se debe generar una URL para descargar desde un bucket en AWS S3
        myItemUrl = await Utils_GenerateS3URL(
          mySelectedImage.storageId,
          myToken
        );
        if (myItemUrl !== null) {
          if (showClg) console.log(myItemUrl);
          requestOptions = {};
          requestOptions = { method: "GET" };
        } else {
          return;
        }
        //#endregion
      } else {
        //#region GET HREF (OLD API - DEPRICATED SEP30/2022)
        const response = await fetch(
          `https://developer.api.autodesk.com/data/v1/projects/${selectedProject.id}/items/${mySelectedImage.id}`,
          requestOptions
        );
        const json = await response.json();
        if (showClg) console.log(response);
        myItemUrl = json.included[0].relationships.storage.meta.link.href;
        if (showClg)
          console.log(`Href: ${mySelectedImage.name} | ${myItemUrl}`);
        //#endregion
      }

      //#region DOWNLOAD IMAGE
      //GET Download Image from URL
      // console.log(downloadedImages);
      const object = downloadedImages.find(
        (item) => item.name === mySelectedImage.name
      );
      // console.log(object);
      if (object) {
        //Use cached object
        if (cancelDownload.current === true) {
          return false;
        } else return object;
      } else {
        const response2 = await fetch(myItemUrl, requestOptions);
        if (showClg) console.log(response2);

        //BLOB
        const myBlob = await response2.blob();
        if (showClg) console.log(myBlob);

        //URL
        const myUrl = URL.createObjectURL(myBlob);
        if (showClg) console.log(myUrl);

        //SET IMAGE
        mySelectedImage.image = myUrl;
        mySelectedImage.myBlob = myBlob;

        if (cancelDownload.current === true) {
          return false;
        } else return mySelectedImage;
      }
      //#endregion
    } catch (error) {
      var myError = `Error de conexión con servidor BIM 360, intenta nuevamente.\nSelecciona de nuevo la unidad: ${error}`;
      // console.error(myError);
      // console.error(mySelectedImage);
      setSoporteTecnico(myError);
      setProgressUnits("");
      return false;
    }
  };

  //#endregion

  //#region UTILS - ITERATE ELLIPSES
  useEffect(() => {
    if (progressUnits !== "") {
      const interval = setInterval(() => {
        var myEllipsis = progressUnits + ".";
        if (myEllipsis.indexOf("....") > -1) {
          myEllipsis = myEllipsis.substring(0, myEllipsis.length - 4);
        }
        setProgressUnits(myEllipsis);
      }, 500);
      return () => {
        clearInterval(interval);
      };
    }
  }, [progressUnits]);
  //#endregion

  //#region UTILS - GENERATE S3 URL
  async function Utils_GenerateS3URL(myStorageId, myToken) {
    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    //#region GENERATE SIGNED S3 URL
    if (myStorageId !== undefined) {
      try {
        var S3BucketId = myStorageId.substring(
          myStorageId.lastIndexOf(":") + 1,
          myStorageId.indexOf("/") + 1
        );
        var S3ObjectId = myStorageId.substring(
          myStorageId.lastIndexOf("/") + 1
        );
        var s3Endpoint = `https://developer.api.autodesk.com/oss/v2/buckets/${S3BucketId}/objects/${S3ObjectId}/signeds3download`;
        //console.log(s3Endpoint);
        const response = await fetch(`${s3Endpoint}`, requestOptions);
        const json = await response.json();
        //console.log(json.url);
        return json.url;
      } catch (error) {
        console.error(`Catch Error - Obteniendo S3 URL: ${error}`);
        setSoporteTecnico(
          `Error de conexión con servidor BIM 360, intenta nuevamente.\nObteniendo URL de descarga: ${error}`
        );
        return null;
      }
    }
    //#endregion
  }
  //#endregion

  //#endregion ------------------------------------------------------------------------------------------------------------------------------

  //#region 04 - CREATE PDF ----------------------------------------------------------------------------------------------------------------
  const createPdf = async () => {
    setShowLoadingExportBtn(true);
    setShowPdfExportBtn(false);
    // console.log(selectedImages);
    //console.log(selectedUnit);
    // console.log(selectedUnit["PLANO"]);
    const myWidth = 1080;
    const myHeight = 720;

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [myWidth, myHeight],
    });
    //console.log(doc);

    //#region Set Date
    let yourDate = new Date();
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
    const myDate = yourDate.toISOString().split("T")[0];
    //#endregion

    //#region Add Image to 1st Page
    const myFile = new File([selectedImages[0].myBlob], selectedImages[0].name);
    const myImg = await resizeImage(myFile);
    doc.addImage(myImg, "JPEG", 0, 0, myWidth, myHeight, "", "FAST");

    //Add Text - CODIGO INMUEBLE
    var codigoInmueble = "";
    const codigoInmuebleTextX = 435;
    const codigoInmuebleTextY = 590;
    if (selectedUnit["CODIGO INMUEBLE"] !== undefined) {
      codigoInmueble = selectedUnit["CODIGO INMUEBLE"];
      doc.setFontSize(22);
      doc.text(codigoInmueble, codigoInmuebleTextX, codigoInmuebleTextY);
    }

    //#region ADD BOXTEXT --------------------------------------------------------
    var parqueaderos = "";
    var depositos = "";
    var vista = "";
    var boxText = "";
    const boxTextX = 162;
    const boxTextY = 600;
    if (
      selectedUnit["PARQUEADEROS"] !== undefined &&
      selectedUnit["PARQUEADEROS"] !== ""
    )
      parqueaderos = "Parqueadero(s): \n" + selectedUnit["PARQUEADEROS"];
    if (
      selectedUnit["DEPOSITOS"] !== undefined &&
      selectedUnit["DEPOSITOS"] !== ""
    )
      depositos = "Depósito: \n" + selectedUnit["DEPOSITOS"];
    if (selectedUnit["VISTA"] !== undefined && selectedUnit["VISTA"] !== "")
      vista = "Vista: " + selectedUnit["VISTA"];

    // if (parqueaderos !== "" && depositos !== "")
    //   boxText = parqueaderos + "\n" + depositos;
    // else if (parqueaderos === "") boxText = depositos;

    boxText =
      parqueaderos +
      (parqueaderos.length > 0 ? "\n" + depositos : depositos) +
      (parqueaderos.length > 0 || depositos.length > 0 ? "\n" + vista : vista);

    doc.setFontSize(14);
    doc.text(boxText, boxTextX, boxTextY);
    //#endregion PARQUEADEROS & DEPÓSITOS -----------------------------------------------------

    //Add Text - Fecha
    const fechaTextX = 410;
    const fechaTextY = 618;
    doc.setFontSize(18);
    doc.text(myDate, fechaTextX, fechaTextY);
    //#endregion

    //#region Add Pages & Images
    for (let i = 1; i < selectedImages.length; i++) {
      const myFile = new File(
        [selectedImages[i].myBlob],
        selectedImages[i].name
      );
      const myImg = await resizeImage(myFile);
      doc.addPage([myWidth, myHeight], "landscape");
      doc.addImage(myImg, "JPEG", 0, 0, myWidth, myHeight, "", "FAST");
      doc.setFontSize(22);
      doc.text(codigoInmueble, codigoInmuebleTextX, codigoInmuebleTextY);
      doc.setFontSize(14);
      doc.text(boxText, boxTextX, boxTextY);
      doc.setFontSize(18);
      doc.text(myDate, fechaTextX, fechaTextY);

      //#region ADD TABLE --------------------------------------------------------
      if (
        selectedUnit.hasOwnProperty("AREA PRIVADA") &&
        selectedUnit["AREA PRIVADA"] !== ""
      ) {
        //Verifica si tiene la columna "AREA PRIVADA" para identificar si la hoja PlanoTipo está actualizada con las referencias para la tabla de áreas.
        const imageName = selectedImages[i].name.substring(
          0,
          selectedImages[i].name.lastIndexOf(".")
        );
        if (selectedUnit["PLANO"] === imageName) {
          autoTable(doc, {
            //startY: 100,
            margin: { top: 85, left: 825 },
            styles: { cellPadding: { horizontal: 7, vertical: 3 } },
            tableLineWidth: 2,
            tableLineColor: [0, 0, 0],
            //columnStyles: { 0: { halign: "center", fillColor: [0, 255, 0] } }, // Cells in first column centered and green
            theme: "grid",
            tableWidth: 200,
            //head: [["Name", "Email", "Country"]],
            body: [
              [
                //HEADER
                {
                  content:
                    selectedUnit["TIPO UNIDAD"].toUpperCase() +
                    " " +
                    selectedUnit["UBICACION"].toUpperCase(),
                  colSpan: 3,
                  styles: {
                    halign: "center",
                    fillColor: [185, 185, 185],
                    fontStyle: "bold",
                    cellPadding: 5,
                    fontSize: 12,
                    //border: 5,
                    //borderColor: [0, 0, 0],
                    lineWidth: 2,
                    lineColor: [0, 0, 0],
                  },
                },
              ],
              [
                //AREA PRIVADA
                {
                  content: "Área privada",
                  //styles: { halign: "center" },
                },
                {
                  content: selectedUnit["AREA PRIVADA"] + " M2",
                  styles: { halign: "right", cellWidth: 100 },
                  colSpan: 2,
                },
              ],
              [
                //AREA CONSTRUIDA
                {
                  content: "Área construida",
                  //styles: { halign: "center" },
                },
                {
                  content: selectedUnit["AREA CONSTRUIDA"] + " M2",
                  styles: { halign: "right", cellWidth: 100 },
                  colSpan: 2,
                },
              ],
              [
                //ALTURA
                {
                  content: "Altura aprox.",
                  //styles: { halign: "center" },
                },
                {
                  content: selectedUnit["ALTURA"] + " Mt",
                  styles: { halign: "right", cellWidth: 100 },
                  colSpan: 2,
                },
              ],
              [
                {
                  content: "Descripción:",
                  styles: {
                    fillColor: [185, 185, 185],
                    fontStyle: "bold",
                    fontSize: 11,
                  },
                  colSpan: 3,
                },
              ],
              [
                {
                  content: selectedUnit["DESCRIPCION"] + ".",
                  /*  "Alcoba principal, Alcoba 2, Alcoba 3, Alcoba de servicio, Vestier, Zona social (Sala - comedor, Hall de acceso y hall de alcobas), Escalera , Espacio disponible, Estudio o Sala de TV, Cocina, Zona de ropas o área de labores, Baño alcoba principal, Baño alcoba 2, Baño alcoba 3, Baño alcobas, Baño de servicio, Baño PMR (personas con movilidad reducida), Baño social, Posible baño, Balcón social, Balcón alcoba principal, Balcón técnico, Patio y Terraza.", */
                  colSpan: 3,
                },
              ],
            ],
          });
        }
      }
      //#endregion ADD TABLE -----------------------------------------------------
    }
    //#endregion

    //#region Save PDF
    doc.save(
      `${codigoInmueble} - ${selectedProject.name.substring(
        selectedProject.name.indexOf("-") + 1
      )} - ${myDate}`
    );
    //var blob = doc.output("blob");
    //window.open(URL.createObjectURL(blob));
    //window.open(doc.output("bloburl", "_blank")); //PROBAR CON ESTE EN iOS

    //#region GOOGLE ANALYTICS
    ReactGA.event({
      action: selectedProject.name,
      category: "PDF Download",
      label: selectedUnit["AGRUPACION"],
    });
    //#endregion

    //#region ADD AWS METRIC
    const myMetric = JSON.stringify({
      requestType: "newMetric",
      metric: {
        client: client,
        user: userData.userData.email,
        metric: "printPdf",
        value1: selectedProject.name,
        value2: selectedUnit["AGRUPACION"],
        sessionTime: TimeMe.getTimeOnCurrentPageInSeconds(),
      },
    });
    if (sendMetric) SendMetric(myMetric);
    TimeMe.stopTimer();
    TimeMe.resetAllRecordedPageTimes();
    TimeMe.startTimer();
    //#endregion

    setSendMetric(false);
    setShowLoadingExportBtn(false);
    setShowPdfExportBtn(true);

    //#endregion
  };

  const resizeImage = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        4374,
        2915,
        "JPEG",
        40,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  //#endregion

  return (
    <Fragment>
      <div /*DEBUG BTN*/ className="debug">
        <button onClick={toggleConsoleLogs}>Debugging</button>
      </div>
      <div
        className="myAppContainer"
        style={{ paddingTop: showImages ? "90px" : "0px" }}
      >
        {/* DROPDOWN PROYECTOS */}
        <div className="dropdownContainer">
          {showDropdownProyectos ? (
            <div name="DropdownProyectos" className="myDropdown">
              <div className="dropdownTittle">Proyecto:</div>
              <Select
                //className="basic-single"
                classNamePrefix="select"
                placeholder="Selecciona un proyecto..."
                //defaultValue={dropdownProyectosOptions[0]}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="Proyectos"
                options={dropdownProyectosOptions}
                styles={dropdownStyle}
                onChange={(value) => {
                  setWarningProyectos("");
                  setShowMultiFichas(false);
                  setShowDropdownUnidades(false);
                  setSelectedFicha("");
                  setSelectedProject({});
                  setShowFichaFound(false);
                  resetStates();
                  setDownloadedImages([]);
                  if (downloadingImage.current === true)
                    cancelDownload.current = true;
                  if (value == null) {
                    if (showClg)
                      console.log(
                        "DROPDOWN PROYECTOS null | Cancel Download: " +
                          cancelDownload.current
                      );
                  } else {
                    const index = projectsList.findIndex(
                      (x) => x.name === value.value
                    );
                    var myProject = JSON.stringify(
                      projectsList[index],
                      null,
                      4
                    );
                    if (showClg)
                      console.log(`[${index}] ${value.value} \n ${myProject}`);
                    setSelectedProject(projectsList[index]);
                    bimNavigateToProjectFiles(projectsList[index]);
                  }
                }}
              />
            </div>
          ) : (
            <p style={myStyles.myText}>Cargando Proyectos...</p>
          )}
        </div>
        {/* WARNING - DROPDOWN PROYECTOS */}
        <div className="warningProyectos">
          {/* {formatProyectoMessage()} */}
          {warningProyectos.length > 0 ? (
            <WarnFormat warns={warningProyectos} />
          ) : null}
        </div>
        {/* BUSCANDO DATA */}
        <div className="buscandoData">
          {/* {formatProyectoMessage()} */}
          {buscandoData ? (
            <p style={myStyles.myText}>
              Buscando Cuadro de áreas e imágenes...
            </p>
          ) : null}
        </div>
        {/*SELECTED FICHA*/}
        <div className="dropdownContainer">
          {showFichaFound ? (
            <div className="myDropdown">
              <div className="dropdownTittle">Cuadro de áreas:</div>
              <span className="selectedFicha">{selectedFicha}</span>
            </div>
          ) : null}
        </div>
        {/* PROGRESS - MULTI FICHAS */}
        <div className="progressMultiFichas">
          {progressMultiFichas !== "" ? (
            <p style={myStyles.myText}>{progressMultiFichas}</p>
          ) : null}
        </div>
        {/* DROPDOWN MULTI FICHAS */}
        <div className="dropdownContainer">
          {showMultiFichas ? (
            <div name="DropdownMultiFichas" className="myDropdown">
              <div className="dropdownTittle">Cuadro de áreas:</div>
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Selecciona un Cuadro de áreas..."
                //defaultValue={dropdownProyectosOptions[0]}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="Fichas Técnicas"
                options={multiFichasOptions}
                styles={dropdownStyle}
                onChange={(value) => {
                  setShowDropdownUnidades(false);
                  resetStates();
                  if (value == null) {
                    console.log("null");
                    setSelectedFicha("");
                    setProgressMultiFichas(
                      "• Se encontraron múltiples Cuadros de áreas."
                    );
                    setSelectedImages([]);
                  } else {
                    setProgressMultiFichas("Descargando ficha técnica...");
                    const index = multiFichasOptions.findIndex(
                      (x) => x.value === value.value
                    );
                    var myFicha = JSON.stringify(
                      multiFichasOptions[index],
                      null,
                      4
                    );
                    if (showClg)
                      console.log(`[${index}] ${value.value} \n ${myFicha}`);
                    bimHrefFichaTecnica(
                      selectedProject,
                      multiFichasOptions[index].id,
                      value.value
                    );
                  }
                }}
              />
            </div>
          ) : null}
        </div>
        {/* WARNING - MULTI FICHAS */}
        <div className="warningMultiFichas">
          {warnMultiFichas !== "" ? (
            <div>
              <h3
                style={{
                  margin: "0px",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                Se encontraron los siguientes errores en BIM 360:
              </h3>
              <p style={myStyles.myText2}>{warnMultiFichas}</p>
            </div>
          ) : null}
        </div>
        {/* DROPDOWN UNIDADES */}
        <div className="dropdownContainer">
          {showDropdownUnidades ? (
            <div name="DropdownUnidades" className="myDropdown">
              <div className="dropdownTittle">Unidad:</div>
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Selecciona una unidad..."
                //defaultValue={dropdownUnidades[0]}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="Unidades"
                options={dropdownUnidadesOptions}
                styles={dropdownStyle}
                onChange={(value) => {
                  resetStates();
                  if (value == null) {
                    if (downloadingImage.current === true)
                      cancelDownload.current = true;
                    if (showClg)
                      console.log(
                        "DROPDOWN UNIDADES null  | Cancel Download: " +
                          cancelDownload.current
                      );
                  } else {
                    const index = unitsList.findIndex(
                      (x) => x.AGRUPACION === value.value
                    );
                    if (showClg) {
                      console.log(
                        `Selected Unit: ${value.value}` +
                          "\n" +
                          JSON.stringify(unitsList[index], null, 4)
                      );
                    }
                    selectUnitDropdown(unitsList[index], index);
                  }
                }}
              />
            </div>
          ) : null}
        </div>
        {/* LISTADO IMAGENES */}
        <div className="ImgList">
          {imageList !== "" ? (
            <div style={myStyles.myText2}>{imageList}</div>
          ) : null}
        </div>
        {/* PROGRESS - UNITS */}
        <div className="progressUnits">
          {progressUnits !== "" ? (
            <p style={myStyles.myText}>{progressUnits}</p>
          ) : null}
        </div>
        {/* WARNING - DROPDOWN UNIDADES */}
        <div className="warningUnits">
          {warningUnits !== "" ? (
            // <p style={myStyles.myText}>{warningUnits}</p>
            <WarnUnitsFormat warns={warningUnits} />
          ) : null}
        </div>
        {/* WARNING - SOPORTE TECNICO */}
        <div className="warnSoporteTecnico">
          {soporteTecnico !== "" ? (
            <div>
              {/* <h3>Lo sentimos, se produjo un error inesperado:</h3> */}
              <h3 style={{ whiteSpace: "pre-wrap" }}>{soporteTecnico}</h3>
            </div>
          ) : null}
        </div>
        {/* SOPORTE TECNICO */}
        <div className="goToAction">
          {!sentEmail &&
          /* soporteTecnico !== "" || */
          /* warningProyectos.length > 0 || */
          (warnMultiFichas !== "" || warningUnits !== "") ? (
            <button
              onClick={sendEmail}
              /*{whatsappClickToChat}*/ className="goToActionBtn"
            >
              <img
                src="/imgs/emailIcon.png" /*{waLogo}*/
                alt="goToActionIcon"
                className="goToActionIcon"
              />
              <p>Solicita aquí soporte técnico</p>
            </button>
          ) : sentEmail ? (
            <div style={myStyles.mySentEmail}>
              <p>
                Gracias por solicitar soporte técnico.<br></br>
                <br></br>
                <span style={myStyles.mySentEmail2}>
                  El equipo de diseño de la constructora ha recibido tu
                  solicitud y estará procesándola cuanto antes.
                  <br></br>Revisa la copia del mensaje en la bandeja de entrada
                  de tu correo.
                </span>
              </p>
            </div>
          ) : null}
        </div>
        {/* EXPORT PDF BUTTON */}
        <div className="goToAction">
          {showPdfExportBtn ? (
            <button onClick={createPdf} className="goToActionBtn">
              <img
                src="/imgs/Icon_PDF.png"
                alt="goToActionIcon"
                className="goToActionIcon"
              />
              Guardar PDF
            </button>
          ) : showLoadingExportBtn ? (
            <div>
              <Loader myText="descargando pdf" />
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                Espera unos segundos.
              </p>
            </div>
          ) : null}
        </div>
        {/*SLIDER IMAGES*/}
        <div className="planoTipoSlider">
          {galleryImage !== "" ? (
            <img
              src={galleryImage}
              alt="GalleryPlanos"
              style={myStyles.myImg}
            ></img>
          ) : null}
        </div>
        {/* IMAGE GRID */}
        <div className="imageGridContainer">
          {showImages ? (
            <div className="imageGrid">
              {selectedImages.map((image, index) => {
                return (
                  <ImgCard
                    img={image.image}
                    title={image.name}
                    subtitle={image.id}
                    key={image.id + index}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
}

export default PlanoTipo;
