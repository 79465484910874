import React from "react";
//import NoImg from "../imgs/NoImage_01.png";

const myStyles = {
  myImg: {
    boxShadow: "0px 0px 5px 1px #979797",
    borderRadius: "10px",
    height: "100%",
    width: "100%",
    maxWidth: "350px",
  },
};

function ImgCard(props) {
  return (
    <div
      className="card"
      // style={{ boxShadow: "inset 0px 0px 0px 1px #cc02ff" }}
    >
      <img
        src={props.img} /*src={NoImg}*/
        className="NoImg"
        alt="NoImg"
        style={myStyles.myImg}
      />
      <div>
        <h2 style={{ color: "#323232", fontSize: "16px" }}>{props.title}</h2>
        {/* <p
          style={{ color: "#323232", fontSize: "12px", wordWrap: "break-word" }}
        >
          {props.subtitle}
        </p> */}
      </div>
    </div>
  );
}

export default ImgCard;
